<template>
  <div class="cardBox" :style="{ backgroundImage: 'url(' + bgimg + ')' }">
    <div class="message">
      <img
        :src="item.avatar ? item.avatar : DEFAULT_AVATAR"
        alt=""
        class="avatrImg"
      />
      <div style="width: 300px">
        <h1 class="textOverflow">{{ item.name }}</h1>
        <small class="textOverflow">{{ item.job }}</small>
        <span class="textOverflow"> {{ item.industry | industryFormat(LOCALE)}}</span>
      </div>
    </div>
    <p class="dsc text_overflow_2" :title="item.company">{{ item.company }}</p>
    <div class="lianxifanshi">
        <div>
            <p><img :src="phoneImg" alt="" /></p>
            <span class="tel textOverflow">{{item.mobile | textFormat}}</span>
        </div>
        <div>
          <p> <img :src="emailImg" alt="" /></p>
          <span class="textOverflow">{{item.email | textFormat}}</span>
        </div>
        <div >
          <p><img :src="location_png" alt="" /></p>
          
          <span class="add" :title="getTitle(item)">
            {{
              item.country
                | countryCityFormat(item.region_en, item.region_zh, LOCALE)
            }}
            {{ item.address=='-'?"":item.address }}
        </span>

      </div>
    </div>
  </div>
</template>
<script>
import { countryCityFormat  } from '@/basePlugins/filters'
export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {
          job: "ZHANGJIAGANG HAOHUA INTERNATIONAL FREIGHT FORWARDERS CO., LTD",
          avatar: "",
          status: "",
          name: "Rico Balingcongan",
          company: "Customer Service Manager",
          industry: "Logistics Supplier - Freight Forwarder",
          country: "Pampanga",
          city: "Philippines",
          address: "7 Gongzhuan Road, Xihu District",
          mobile: "15838061196",
          email: "15838061196@163.com",
        };
      },
    },
  },
  methods:{
    getTitle(item){
      return `${countryCityFormat(item.country,item.region_en, item.region_zh, this.LOCALE)} ${item.address=="-"?"":item.address}`
    },
  },
  data() {
    return {
      bgimg: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/bg_blue.png",
      phoneImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/phone.png",
      emailImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteUseing/atfwebsite/email.png",
      location_png: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/location_png.png",
    };
  },
};
</script>
<style lang="less" scoped>
.cardBox {
  height: 240px;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 10px 20px;
  .message {
    height: 80px;
    display: flex;
    img {
      width: 80px;
      height: 80px;
    }
    div {
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      width: 100%;
      h1 {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
      small {
        height: 22px;
        font-size: 12px;
        font-weight: 400;
        color: #a6d1ff;
        line-height: 22px;
      }
      span {
        height: 24px;
        font-size: 12px;
        font-weight: 400;
        color: #f4faff;
        line-height: 24px;
      }
    }
  }
  .dsc {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 376px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    overflow: hidden;
  }
  .tel_email {
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
      img {
        padding-right: 9px;
      }
      .tel {
        width: 102px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
      .email {
        width: 165px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }
  .address {
    margin-top: 12px;
    display: flex;
    align-items: center;
    img {
      padding-right: 9px;
    }
    div {
      width: 100%;
      height: 22px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
  .avatrImg {
    border-radius: 50%;
  }
}

.lianxifanshi {
  div {
    display: flex;
    align-items: top;
    margin-bottom: 5px;
    img {
      width: 15px;
      margin-right: 7px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
    .add {
      width: 345px;
      // height: 38px;
      word-break:break-all;
      display:-webkit-box;/**对象作为伸缩盒子模型展示**/
      -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
      -webkit-line-clamp:1;/**显示的行数**/
      overflow:hidden;/**隐藏超出的内容**/
    }
  }
}
</style>